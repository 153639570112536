import { tw } from '@/utils/tw';
import { default as BaseCoverBox } from 'base/components/CoverBox/theme';

const Prose = tw.theme({
  extend: BaseCoverBox.Prose,
  base: 'prose-coverbox',
});

const CoverBox = tw.theme({
  extend: BaseCoverBox,
  slots: {
    base: [
      'bg-transparent',
      'wings-sand-50',
      'grid-cols-1',
      'py-3',
      'md:py-6',
      'lg:grid-cols-[1fr_auto]',

      "before:bg-[url('/image/rundlar.svg')]",
      'before:bg-no-repeat',
      'before:bg-left',
      'before:bg-[length:150px_200px]',
      'before:md:bg-[length:200px_250px]',

      "after:bg-[url('/image/rundlar.svg')]",
      'after:bg-no-repeat',
      'after:bg-right',
      'after:bg-[length:150px_200px]',
      'after:md:bg-[length:200px_250px]',
    ],
    headline: 'whitespace-nowrap text-headline-md md:text-headline-2xl',
    icon: 'lg:row-span-2',
    toggle: 'bg-sand-50 text-headline-2xs sm:text-headline-2xs underline',
  },
});

export default Object.assign(CoverBox, { Prose });
